//GLOBAL - START

$border-radius-big: 0;
$border-radius-small: 0;
$border-radius-buttons: 0;

$global-font-size: 16px;
$global-line-height: 1.4;

//GLOBAL - END

// FONTS - START

$font-family: "Open Sans", Helvetica, Arial;
$font-family-headline: "Open Sans", Helvetica, Arial;
$font-family-buttons: "Open Sans", Helvetica, Arial;

// FONTS - END

//BASE COLORS - START

$orm_red: #de1f21; // IMPORTANT ERROR CLASSES OVERWRITE DEFAULT
$orm_green: #fdc300; // IMPORTANT ERROR CLASSES OVERWRITE DEFAULT
$orm_black: #000000;

$customer_color_text: #fff;
$customer_color_text_dark: #000000;

$customer_color_grey_dark: #d5d6d6; //#1a171b;
$customer_color_grey_md: #ececed; //#1a171b;
$customer_color_grey_light: #ececed; //#1a171b;
$customer_color_primary: #0066b2;
$customer_color_secondary: #003867;

$customer_color_white: #fff;

$customer_bg_color: #fff;
$customer_bg_color_secondary: darken($customer_bg_color, 3%);
//used in Locationmap

$backgroundcta1: $customer_color_grey_light;
$backgroundcta2: $customer_color_white;
//BASE COLORS - END

// TEXT COLOR - START

$orm_txt_dark_color: $customer_color_text_dark;
$orm_txt_light_color: $customer_color_text;

$orm_txt_headline_searchbar: $customer_color_text;
$orm_txt_headline_searchbar_dark: $customer_color_text_dark;

$orm_txt_headline_locationmap: $customer_color_text;
$orm_txt_headline_locationmap_dark: $customer_color_text_dark;

//TEXT COLOR - END

//BACKGROUND COLOR - START

$orm_bg_dark_color: $customer_color_grey_dark;
$orm_bg_lt_grey_color: $customer_color_grey_light;
$orm_bg_light_color: $orm_white;

//BACKGROUND COLOR - END

//BUTTONS - START
$orm_cta_button_color: $customer_color_primary;
$orm_cta_button_hover_color: $customer_color_secondary;

$orm_cta_button_outline_color: $customer_color_primary;
$orm_cta_button_outline_hover_color: $customer_color_primary;
$orm_cta_button_outline_border_hover: $customer_color_primary;
$orm-cta-button-outline-hover-text-color: $customer_color_white;

$orm_cta2_button_color: $customer_color_secondary;
$orm_cta2_button_hover_color: $customer_color_secondary;

$orm_cta2_button_outline_color: $customer_color_secondary;
$orm_cta2_button_outline_hover_color: $customer_color_white;
$orm_cta2_button_outline_border_hover: $customer_color_secondary;

//BUTTONS - END

//SEARCHBAR - START
$orm_searchbar_bg: $customer_bg_color;
$orm_searchbar_color: $customer_color_text_dark;
//// INPUTS
$orm_search_input_bg: $customer_color_white;
$orm_search_input_color: $customer_color_text_dark;
$orm_search_input_border: $customer_color_text_dark;
//SEARCHBAR - ENDE

//// RESERVATION
$orm_reservation_input_border: $customer_color_text_dark;
$orm_reservation_input_bg: $customer_color_grey_dark;
$orm_reservation_input_color: $customer_color_text_dark;

//SEARCHBAR - END

//MAP - START
$orm_map_stationlist_bg1: $customer_bg_color;
$orm_map_stationlist_color1: $customer_color_text_dark;

$orm_map_stationlist_bg2: $customer_bg_color_secondary;

$orm_map_stationlist_color2: $customer_color_text_dark;

//// INPUT
$orm_map_input_bg: $customer_color_white;
$orm_map_input_color: $customer_color_text_dark;
$orm_map_input_border: $customer_color_text_dark;
//MAP - END

//MAP MARKER - START
$orm_map_marker_rental_color: #0066b2;
$orm_map_marker_sharing_color: $customer_color_secondary;
//MAP MARKER - END

//RESERVATION - START
$orm_header_bg: $customer_color_white;

$orm_header_step_color: $customer_color_text_dark;
$orm_header_step_border: $customer_color_text_dark;

$orm_header_step_active_color: $customer_color_white;
$orm_header_step_active_bg: $customer_color_grey_dark;

$orm_header_step_checked_color: $customer_color_white;
$orm_header_step_checked_bg: $orm_green;

$orm_reservation_bg: #ececed;
$orm_reservation_inner_bg: $customer_color_white;
$orm_reservation_borderleft: $customer_color_grey_md;

//RESERVATION - END

//FOOTER - START

$orm_footer_bg: #d5d6d6;
$orm_footer_link_color: #000000;
$orm_footer_link_hover: #0066b2;

//FOOTER - END

// OFFERS - START
$orm_offers_background: rgba($orm_black, 0.8);
$orm_offers_text: $customer_color_white;
$orm_offers_dots: $customer_color_secondary;
$orm_offers_dots_inner: $customer_color_secondary;
// OFFERS - END

//POSSIBLE OTHERS BUT WORTST CASE

// $orm_form_border_color: $orm_dk_grey;
// $orm_form_bg_color: $orm_white;
// $orm_form_text_inactive_color: $orm_dk_bluegrey;
// $orm_form_bg_inactive_color: $orm_lt_grey;
// $orm_form_checked_color: $orm_green;
// $orm_form_focus_color: $orm_black;
// $orm_form_placeholder_color: $orm_md_grey;
// $orm_form_error_color: $orm_red;
// $orm_calendar_selected_color: $orm_dk_bluegrey;
// $orm_calendar_not_available_color: $orm_md_grey;
// $orm_calendar_occupied_color: $orm_red;
// $orm_calendar_partially_available: $orm_orange;

// $orm_timepicker_selected: $orm_black;

// $orm_loading_bar: $orm_md_grey;
// $orm_loading_bar_border: $orm_dk_grey;
// $orm_loading_bar_progress: $orm_red;
