.orm {
  &-stationselect {
    height: rem-calc(48px);
    margin-bottom: rem-calc(16px);
    border: 1px solid $orm_search_input_border;
    //max-width: rem-calc(264px);

    border-radius: $border-radius-small;
    position: relative;
    cursor: pointer;

    background-color: $orm_search_input_bg;
    color: $orm_search_input_color;

    strong {
      color: $orm_search_input_color;
    }

    .orm-reservationInfobar & {
      background-color: $orm_reservation_input_bg;
      color: $orm_reservation_input_color;
      border: 1px solid $orm_reservation_input_border;

      strong {
        color: $orm_reservation_input_color;
      }

      .orm-stationselect__option {
        strong {
          color: $orm_black;
        }
        &:hover,
        &:focus {
          background-color: $orm_bg_dark_color;
          color: $orm_txt_light_color;

          strong {
            color: $orm_txt_light_color;
          }
        }
      }
    }

    &--disabled {
      * {
        cursor: default !important;
      }
    }

    &__select {
      height: rem-calc(48px);
      padding: rem-calc(5px) rem-calc(8);
      cursor: pointer;
      font-size: rem-calc(15px);
      line-height: 1.25;
      strong {
        font-weight: 600;
        white-space: nowrap;
        width: 90%;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
      }
      small {
        font-size: rem-calc(12px);
        font-weight: 400;
      }
    }

    &__options {
      background-color: transparentize($white, 0.05);
      padding: 20px 0 20px 0;
      position: absolute;
      top: -50%;
      z-index: 500;
      border-radius: $border-radius-big;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      width: 100%;
      font-size: rem-calc(15px);
      line-height: 1.25;
      max-height: 278px;
      overflow: auto;
      strong {
        font-weight: 600;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
      }
      small {
        font-size: rem-calc(12px);
        font-weight: 400;
      }

      // @media (max-width: $sm-viewport) and (max-height: 700px) {
      //   position: fixed;
      //   width: auto;
      //   top: 5px;
      //   max-height: calc(100vh - 50px);
      //   overflow: auto;
      // }
    }
    &__option {
      cursor: pointer;
      height: 48px;
      padding: 6px 20px;
      color: $orm_black;
      cursor: pointer;
      transition: background-color ease-in-out 0.25s, color ease-in-out 0.25s;
      &:nth-child(even) {
        background-color: $grey-light;
      }

      &:hover,
      &:focus,
      &.selected {
        background-color: $orm_bg_dark_color;
        color: $orm_txt_light_color;

        strong {
          color: $orm_txt_light_color;
        }
      }
    }
    &__endstation__unselected {
      padding-bottom: rem-calc(16px);
      cursor: pointer;
      .orm-fakecheck {
        display: inline-block;
        width: rem-calc(18px);
        height: rem-calc(18px);
        border: 1px solid $orm_reservation_input_border;
        border-radius: 4px;
        margin-right: rem-calc(8px);
      }
      @media (min-width: $sm-viewport) and (max-width: $lg-viewport) {
        padding-top: rem-calc(12px);
      }
      @media (min-width: $lg-viewport) {
        padding-top: 0;
        padding-bottom: 0;
      }
      &.orm-lmap {
        padding-top: 0;
        padding-bottom: rem-calc(16px);
      }
    }

    &__label {
      font-size: rem-calc(12px);
      font-weight: 400;
      //float: left;
      display: inline-block;
      transform: translateY(-5px);
    }
  }
}
.orm-searchbar__datepicker
  .orm-stationselect__endstation__unselected
  .orm-fakecheck {
  border: 1px solid $orm_search_input_border;
}
.orm-background--greytowhite .orm-fakecheck {
  border: 1px solid $customer_color_text_dark;
}
.orm-stationselect__endstation__unselected.orm-lmap .orm-fakecheck {
  border: 1px solid $orm_map_input_border;
}
.orm-station__address__icon {
  font-size: rem-calc(20px);
  align-self: flex-end;
  position: absolute;
  bottom: 5px;
  right: 7px;
}

.orm-station__caret__icon {
  font-size: rem-calc(20px);
  align-self: flex-end;
  position: absolute;
  bottom: 10px;
  right: 7px;
}
.orm-stationselect__select__textmiddle {
  padding-top: 9px;
}
